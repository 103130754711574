import type { GetStaticPropsContext } from "next";
import { server as env } from "@config/env/server";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { Container } from "@components/ui";
import { useTranslation } from "next-i18next";
import { MainLayout } from "@layouts/MainLayout/MainLayout";
import { SliderProducts } from "@components/pages/slider/SliderProducts/SliderProducts";
import { SliderReviews } from "@components/pages/slider/SliderReviews/SliderReviews";
import { BAR_PRISMIC_TYPE } from "@middleware/constants";
import { Head } from "@components/commun";
import { Content } from "@prismicio/client";
import { SliceZone } from "@prismicio/react";
import { usePromotionFooter } from "@middleware/hooks";
import { components } from "../../../src/slices";
import { createClient } from "../../prismicio";

type PageProps = {
  page: Content.HomepageDocument;
  slider: Content.BlockSliderDocument;
  reviews: Content.AvisDocument[];
};

export async function getStaticProps({
  locale,
  previewData,
}: GetStaticPropsContext) {
  const client = createClient({ previewData });
  const page = await client.getSingle("homepage");
  const slider = await client.getSingle("block_slider");
  const reviews = await client.getAllByType("avis");

  return {
    props: {
      ...(await serverSideTranslations(locale as string, [
        "common",
        "header",
        "footer",
      ])),
      page,
      slider,
      reviews,
    },
    revalidate: parseInt(env.TUNNEL_CONTENT_CACHE),
  };
}

const Home = ({ page, slider, reviews }: PageProps) => {
  const { t } = useTranslation();
  const { promotionFooterSlices } = usePromotionFooter(page);

  return (
    <Container className="page-cms homepage">
      <SliceZone slices={promotionFooterSlices} components={components} />
      <Head
        title={t("seo.titleHomePage")}
        description={t("seo.descriptionHomePage")}
      />
      <SliceZone slices={page.data.slices} components={components} />
      <SliderReviews reviews={reviews} isHomePage={true} />
      <SliderProducts
        page={slider}
        bar={
          page.data.slices.find(
            (slice) => slice.slice_type === BAR_PRISMIC_TYPE,
          ) as Content.BarreProgrammeSlice
        }
      />
    </Container>
  );
};

Home.layout = MainLayout;
export default Home;
